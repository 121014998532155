import { Button, Checkbox, FormControlLabel, Stack, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment';
import business from 'moment-business';
import { useAppAuth, useAppDispatch, useAppSelector, useCreateNewJob, useSaveJob } from '../../hooks';
import { jobSendToJiraValidationSchema, jobSubmitValidationSchema, validationSchemas } from '../../application/common';
import {
  emptyJob,
  FormSteps,
  JiraTicketType,
  JobType,
  selectDvEmailBody,
  selectIsGetMethodError,
  selectIsLimitedAccess,
  selectIsReadOnly,
  selectUiActiveStep,
  setActiveStep,
  setIsReadOnly,
  useCreateDiligenceVaultProjectsMutation,
  useCreateNewJiraTicketMutation,
  useRunJobSubmitMutation,
} from '../../reducers';
import { FieldDivider, InformationCard } from '../styles';
import { JobSelection } from './JobSelection';
import { MainSelection } from './MainSelection';
import { ManagerSubmission } from './ManagerSubmission';
import { Strategies } from './Strategies';
import Header from '../Header';
import { FormValidations } from './FormValidations';
import { StrategiesGridValidationError, useValidateStrategiesGrid } from './Strategies/StrategiesGrid';

const Home = () => {
  const dispatch = useAppDispatch();

  const [initialValues, setInitialValues] = useState<JobType>(emptyJob);

  const [createNewJiraTicket, createNewJiraTicketResult] = useCreateNewJiraTicketMutation();
  const [createDVProjects, createDVProjectsResult] = useCreateDiligenceVaultProjectsMutation();
  const [runJobSubmit, runJobSubmitResult] = useRunJobSubmitMutation();

  const { triggerCreateNewJob, createNewJobResult } = useCreateNewJob();
  const { saveJob, saveJobResult } = useSaveJob();

  const activeStep = useAppSelector(selectUiActiveStep);
  const dvEmailBody = useAppSelector(selectDvEmailBody);
  const isGetMethodError = useAppSelector(selectIsGetMethodError);
  const isLimitedAccess = useAppSelector(selectIsLimitedAccess);
  const isReadOnly = useAppSelector(selectIsReadOnly);

  const { isUnauthorized } = useAppAuth();

  const { validationSchema } = validationSchemas[activeStep];
  const smallScreen = useMediaQuery('(max-width: 821px)');

  const allSteps = [
    { title: 'Main Selection', content: <MainSelection /> },
    { title: 'Strategies Selection', content: <Strategies /> },
    { title: 'Manager Submission', content: <ManagerSubmission /> },
  ];

  const steps = allSteps.filter((step) => (isLimitedAccess ? step.title !== 'Manager Submission' : true));

  useEffect(() => {
    const { data = [], isError, isLoading } = createDVProjectsResult;
    if (!isError && !isLoading && data.length) {
      const failedResults = data.filter((res) => res.status === 'fail');
      if (failedResults.length) {
        toast.warning('Error creating some Diligence Vault Projects');
        failedResults.forEach((res) => toast.error(res.error));
      } else {
        toast.success('Dillegence Vault project created successfully');
      }
    }
  }, [createDVProjectsResult]);

  useEffect(() => {
    const { data, isError, isLoading } = runJobSubmitResult;
    if (!isError && !isLoading && data?.length) {
      if (data.includes('SUCCESS')) toast.success('Job submitted successfully');
      else toast.error(`Job is not valid ( ${data.join(', ')} ).`);
    }
  }, [runJobSubmitResult]);

  useEffect(() => {
    dispatch(setIsReadOnly(initialValues.jobStatus === 'Job Canceled' || isGetMethodError));
  }, [dispatch, initialValues.jobStatus, isGetMethodError]);

  const a11yProps = (index: number) => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    form: `form-${activeStep + 1}`,
    type: 'submit',
  });

  const tabsOrientation = () => {
    if (smallScreen) {
      return 'horizontal';
    } else {
      return 'vertical';
    }
  };

  const toggleIsCanceled = (
    values: JobType,
    setFormikValues: FormikHelpers<JobType>['setValues'],
    setTouched: FormikHelpers<JobType>['setTouched'],
  ) => {
    setFormikValues({ ...values, isCanceled: !values.isCanceled });
    setTouched({});
  };

  const { validateStrategiesGrid } = useValidateStrategiesGrid();

  const handleTabChange = async (job: JobType, newFormStep: number) => {
    const customValidation = newFormStep === FormSteps.ManagerSubmission ? validateStrategiesGrid : undefined;
    try {
      await saveJob({ job, customValidation }).then(() => dispatch(setActiveStep(newFormStep)));
    } catch (err: any) {
      if (err instanceof StrategiesGridValidationError) err.errors.forEach((msg) => toast.error(msg));
      console.error('Form not valid. It`s NOT allowed to change tabs');
    }
  };

  const handleSave = async (job: JobType) => {
    try {
      await saveJob({ job });
    } catch (err: any) {
      console.error('Form not valid.');
    }
  };

  const extractJiraUser = (workEmail: string) =>
    workEmail.includes('|') ? workEmail.split('|')[1] : workEmail.split('@')[0];

  const handleSendToJira = async (job: JobType) => {
    try {
      if (!job.isCanceled) {
        jobSendToJiraValidationSchema.validateSync(job);
        validateStrategiesGrid(job);
        await saveJob({ job });
        const newJiraTicket: JiraTicketType = {
          isConsultant: isLimitedAccess,
          assignee: '',
          description: `${job.jobName} [ ${job.jobID} ]`,
          jobID: job.jobID,
          participants: extractJiraUser(job.requestorEmail),
          reporter: extractJiraUser(job.primaryConsultantEmail),
          summary: job.jobName,
        };
        createNewJiraTicket(newJiraTicket)
          .unwrap()
          .then(() => {
            if (isLimitedAccess) setIsReadOnly(true);
            toast.success('Jira ticket created successfully');
          })
          .catch(({ data }) => console.error(`Job not sent to Jira. Error: ${data.error}`));
      }
    } catch (err: any) {
      if (err instanceof StrategiesGridValidationError) err.errors.forEach((msg) => toast.error(msg));
      toast.error(`Job not sent to Jira. Error: ${err.status || err.message}`);
    }
  };

  const handleSubmitJob = async (job: JobType) => {
    try {
      if (!job.isCanceled) {
        jobSubmitValidationSchema.validateSync(job);
        if (job.jiraIssueKey) {
          validateStrategiesGrid(job);
          return await runJobSubmit(job.jobID).unwrap();
        } else {
          toast.error('Job not submitted. Please create the Jira ticket before submit the job!');
        }
      }
    } catch (err: any) {
      if (err instanceof StrategiesGridValidationError) err.errors.forEach((msg) => toast.error(msg));
      toast.error(`Job not submitted to VisionFI. Error: ${err.status || err.message}`);
    }
  };

  const handleCreateDVProjects = async (values: JobType) => {
    const asOfDate = moment().format('YYYY-MM-DD');
    const { jobID, jobName, strategies = [] } = values;
    const dueAt = business.addWeekDays(moment(), 3).format('YYYY-MM-DD');
    const emailText = dvEmailBody.replace(/\n/g, '<br />');
    const name = jobName;
    const strategiesShortCode = strategies.map((strategy) => strategy.strategyShortCode);

    try {
      await createDVProjects({
        jobID,
        asOfDate,
        dueAt,
        emailText,
        name,
        strategiesShortCode,
      }).unwrap();
      toast.success('Created DV Project successfully');
    } catch (err: any) {
      if (!err.status) toast.success('Create DV Project action process started.');
      else console.error(`Create DV Project action failed. Error: ${err.status}`);
    }
  };

  return (
    <>
      <Header pageName="Research Document Request Portal" btnRedirectToName="Shadow" btnRedirectToURL="/shadow" />
      <ToastContainer
        position="top-right"
        autoClose={8000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
      {isUnauthorized ? null : (
        <Stack
          sx={{
            display: 'flex',
            gap: '1rem',
            width: '100%',
            alignItems: 'center',
          }}
          marginBottom={2}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={!isReadOnly ? validationSchema : null}
            onSubmit={() => undefined}
            enableReinitialize
            validateOnMount
            validateOnChange
            validateOnBlur
          >
            {({ values, setTouched, setValues: setFormikValues }) => (
              <>
                <FormValidations />
                <JobSelection setInitialValues={setInitialValues} />
                <Stack direction="row" spacing={2} justifyItems="self-start" style={{ width: 'calc(95% + 2rem)' }}>
                  <InformationCard>
                    <h4>Name: {values.jobName}</h4>
                    <h4>Id: {values.jobID}</h4>
                    {values.jiraIssueKey ? <h4>Jira Ticket Number: {values.jiraIssueKey}</h4> : null}
                    <h4>Status: {values.jobStatus}</h4>
                  </InformationCard>
                  <Stack spacing={1} width="15rem" justifyContent="space-between">
                    <Button
                      id="new-job-btn"
                      variant="contained"
                      disabled={createNewJobResult.isLoading}
                      onClick={triggerCreateNewJob}
                      disableElevation
                    >
                      New Job
                    </Button>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Button
                        disabled={isGetMethodError || isReadOnly || saveJobResult.isLoading}
                        form={`form-${activeStep + 1}`}
                        fullWidth
                        onClick={() => handleSave(values)}
                        type={values.isCanceled ? 'button' : 'submit'}
                        variant="contained"
                      >
                        Save
                      </Button>
                      <FormControlLabel
                        value="Cancel job"
                        control={
                          <Checkbox
                            id="cancel-job-btn"
                            checked={values.isCanceled}
                            color="error"
                            disabled={isReadOnly}
                            onChange={() => toggleIsCanceled(values, setFormikValues, setTouched)}
                          />
                        }
                        label="Cancel job"
                        sx={{ whiteSpace: 'nowrap' }}
                        labelPlacement="start"
                      />
                    </Stack>
                    {activeStep === FormSteps.StrategySelections && !values.jiraIssueKey && (
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Button
                          id="send-to-jira-btn"
                          disabled={isReadOnly || createNewJiraTicketResult.isLoading}
                          form={`form-${activeStep + 1}`}
                          fullWidth
                          onClick={() => handleSendToJira(values)}
                          type="submit"
                          variant="contained"
                        >
                          Send to Jira
                        </Button>
                      </Stack>
                    )}
                    {activeStep === FormSteps.ManagerSubmission &&
                      values.jobStatus !== 'SearchBook Generated' &&
                      values.jobStatus !== 'SearchBook Submitted' && (
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Button
                            id="submit-job-btn"
                            disabled={isReadOnly || runJobSubmitResult.isLoading}
                            form={`form-${activeStep + 1}`}
                            fullWidth
                            onClick={() => handleSubmitJob(values)}
                            type="submit"
                            variant="contained"
                          >
                            Submit job
                          </Button>
                        </Stack>
                      )}
                    {activeStep === FormSteps.ManagerSubmission && !values.isIntegratedToDV && (
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Button
                          id="create-dv-project-btn"
                          disabled={isReadOnly || createDVProjectsResult.isLoading}
                          form={`form-${activeStep + 1}`}
                          fullWidth
                          onClick={() => handleCreateDVProjects(values)}
                          type="submit"
                          variant="contained"
                        >
                          Create DV Project
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <FieldDivider>
                  <Form id={`form-${activeStep + 1}`}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Tabs
                        orientation={tabsOrientation()}
                        value={activeStep}
                        variant="scrollable"
                        aria-label="Vertical tabs example"
                        onChange={(e, newTabID) => handleTabChange(values, newTabID)}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        {steps.map(({ title }, index) => (
                          <Tab
                            disabled={isGetMethodError}
                            label={title}
                            key={title.trim()}
                            {...a11yProps(index)}
                            wrapped
                          />
                        ))}
                      </Tabs>
                      <Stack width="100%" overflow="overlay">
                        {steps[activeStep].content}
                      </Stack>
                    </Stack>
                    <Stack spacing={2} marginTop="2rem" direction="row" justifyContent="space-between">
                      {activeStep > 0 && (
                        <Button
                          disabled={isGetMethodError}
                          id="back-tab-btn"
                          type="submit"
                          onClick={() => handleTabChange(values, activeStep - 1)}
                          fullWidth
                          color="inherit"
                        >
                          Back
                        </Button>
                      )}
                      {activeStep < steps.length - 1 && (
                        <Button
                          disabled={isGetMethodError}
                          id="next-tab-btn"
                          type="submit"
                          onClick={() => handleTabChange(values, activeStep + 1)}
                          fullWidth
                        >
                          Next
                        </Button>
                      )}
                    </Stack>
                  </Form>
                </FieldDivider>
              </>
            )}
          </Formik>
        </Stack>
      )}
    </>
  );
};

export default Home;
